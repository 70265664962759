<template>
  <div>
    店舖已選擇, 請關閉此頁<br>
    {{$route.query}}
  </div>
</template>

<script>
export default {
  name: 'FamilyMartData',
  data() {
    return {
    };
  },
  created() {
    localStorage.setItem(
      'fm',
      JSON.stringify(this.$route.query),
    );
    window.close();
  },
};
</script>
